<template>
  <mf-dialog v-model="dialog" max-width="1600" :title="title" persistent>
    <v-row v-if="sale && sale.items" class="mx-1 mt-n6">
      <v-col>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters justify="end">
              <v-col v-if="sale.temporary_churn_period" class="mx-2" cols="auto">
                <date-picker label="Data inicio do abono temporário" :date.sync="sale.temporary_churn_period.start_date" disabled outline />
              </v-col>
              <v-col v-if="sale.temporary_churn_period" cols="auto">
                <date-picker label="Data fim do abono temporário" :date.sync="sale.temporary_churn_period.end_date" disabled outline />
              </v-col>
              <v-col cols="auto">
                <h2 class="mx-4 mb-0 mt-2">Total: {{ total }}</h2>
              </v-col>
            </v-row>
            <v-row v-if="sale.type === 'new_sale' || sale.type === 'cross_sell' || sale.type === 'upsell'" no-gutters justify="end">
              <v-col cols="auto">
                <headers-data-table :headers.sync="headers" :default-headers="buildHeader" :headers-settings-name="headersSaleFranchise" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          class="mx-3"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          :headers="sale.type === 'new_sale' || sale.type === 'cross_sell' || sale.type === 'upsell' ? computedHeaders : headers"
          :items="sale.items"
        >
          <template v-slot:[`item.unit_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseCurrency(item.unit_value) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.name || getProductFromType(item.type).name }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.charge_type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' ? (item.charge_type === 'recurring' ? 'Recorrente' : 'Única') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.is_accumulative`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' ? (item.is_accumulative ? 'Sim' : 'Não') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.has_order`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'mercashop' ? (item.has_order ? 'Sim' : 'Não') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.implantation_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getProductFromType(item.type).hasSetupCost ? parseCurrency(item.implantation_value) : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.total_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseCurrency(item.total_value) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.immediate_billing`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.immediate_billing ? 'Sim' : 'Não' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.plan`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <span> {{ item.plan }} </span>
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.billing_date_periods`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.billing_date_periods ? item.billing_date_periods : '-' }}
              </v-layout>
            </v-flex>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:[`item.ambev_incentive_months`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ sale.ambev_incentive ? sale.ambev_incentive.ambev_incentive_months : 0 }}
              </v-layout>
            </v-flex>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:[`item.ambev_incentive_text`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ sale.ambev_incentive ? sale.ambev_incentive.ambev_incentive_text : '-' }}
              </v-layout>
            </v-flex>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mx-1">
      <v-col cols="6">
        <mf-button color="error" label="Sair" @click="close"></mf-button>
      </v-col>
    </v-row>
  </mf-dialog>
</template>

<script>
import { QUERY_GET_FRANCHISE_SALE_BY_ID } from '@/modules/franchises/graphql'
import { tableHeaders } from '@/constants/sales'
import { getSaleTypeByValue } from '@/constants/salesTypes'
import { headers, productsInformation } from '@/mixin'
import { cloneDeep } from 'lodash'
export default {
  components: {
    DatePicker: () => import('@/components/calendar/DatePicker'),
    HeadersDataTable: () => import('@/components/atomic-components/molecules/HeadersDataTable')
  },
  mixins: [headers, productsInformation],
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    saleId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    sale: null,
    headers: [],
    headerDefault: []
  }),
  computed: {
    headersSaleFranchise() {
      if (this.sale) return `headers-sale-franchise-${this.sale.type}-`
      return ''
    },
    title() {
      if (this.sale) return 'Detalhes ' + this.modalTitle(this.sale.type)
      return ''
    },
    buildHeader() {
      let headers = tableHeaders[this.sale.type].filter(item => item.value !== 'units')
      if (!headers) return tableHeaders.new_sale.filter(item => item.value !== 'units')
      return headers
    },
    darkMode: {
      get() {
        return localStorage.getItem('dark_theme') === 'true'
      }
    },
    total() {
      let totalValue = 0
      this.sale.items.map(item => (totalValue += item?.total_value))
      return this.parseCurrency(totalValue)
    }
  },
  apollo: {
    sales: {
      query: QUERY_GET_FRANCHISE_SALE_BY_ID,
      fetchPolicy: 'network-only',
      variables() {
        return { sale_id: this.saleId }
      },
      update({ franchiseSaleById }) {
        this.sale = franchiseSaleById
        this.headers = cloneDeep(this.buildHeader)
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    modalTitle(saleType) {
      return getSaleTypeByValue(saleType)?.label ?? ''
    },
    close() {
      this.$emit('close')
      this.sale = null
    },
    parseCurrency(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })

      return formatter.format(value)
    },
    mapIcon(type) {
      const mapIcons = {
        app: 'mdi-cellphone-text',
        cbm: 'mdi-archive',
        ecommerce: 'mdi-briefcase-plus',
        email: 'mdi-email-arrow-right-outline',
        sms: 'mdi-message-alert-outline',
        whatsapp: 'mdi-whatsapp'
      }

      return mapIcons[type]
    }
  }
}
</script>
